import React from "react";
import "./about.css";

function About() {
    return (
    <div id="about" className="aboutContainer">
      <div className="container">
        <div className="aboutContent">
          <h2 className="aboutContentTitle">About Smooth Coats Studio</h2>
          <h4 className="aboutContentSubTitle"> Transform Your Space with Precision and Artistry.</h4>
            <p className="aboutContentDescription">At Smooth Coat Studios, we're more than just painters. We're artisans dedicated to crafting stunning interiors that reflect your unique style. With meticulous attention to detail and a passion for perfection, we turn your vision into reality.</p>
            
          <div className="founder-info">
            <div className="founder-details">
              <h3>Abby Newell</h3>
              <p>Founder & CEO</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;